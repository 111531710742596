/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600&family=Koulen&display=swap");

.contact-card-container-overlay {
  position: relative;
}

.contact-workers-image {
  width: 100%;
  height: auto;
}

.contact-overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  color: white;
}

.contact-text-container-overlay {
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.contact-text-container-overlay h1 {
  font-family: "Koulen", sans-serif;
  font-size: 96px;
  margin: 0 0 10px 0;
}

.contact-text-container-overlay p {
  font-family: "Inter", sans-serif;
  font-weight: 200; /* Extra Light */
  font-size: 20px;
  margin: 0;
}

.contact-sections-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #f9f9f9;
  gap: 0;
}

.contact-sections-container p {
  color: black;
}

.contact-left-section,
.contact-right-section {
  flex: 1 1 45%;
  padding: 100px;
  box-sizing: border-box;
}

.contact-left-section {
  background-color: #e0e0e0;
  text-align: center;
}

.contact-left-section h2,
.contact-right-section h2 {
  font-family: "Koulen", sans-serif;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.contact-left-section p {
  font-family: "Inter", sans-serif;
  font-weight: 400; /* Regular */
  font-size: 16px;
  margin-bottom: 20px;
}

.spacer-1 {
  margin-bottom: 10px;
}

.spacer-2 {
  margin-bottom: 20px;
}

.spacer-3 {
  margin-bottom: 30px;
}

.quote-button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  margin: 0 auto;
  font-family: "Inter", sans-serif;
  font-weight: 600; /* Semi Bold */
  font-size: 20px;
}

.contact-left-section hr + p {
  font-family: "Inter", sans-serif;
  font-weight: 600; /* Semi Bold */
  font-size: 20px;
}

.contact-right-section {
  background-color: #ffa500;
  padding: 100px;
}

.contact-right-section form {
  display: flex;
  flex-direction: column;
}

.contact-right-section label {
  font-family: "Inter", sans-serif;
  font-weight: 600; /* Semi Bold */
  font-size: 24px;
  margin-top: 10px;
}

.contact-right-section .required::after {
  content: " *";
  color: red;
}

.contact-right-section input,
.contact-right-section textarea {
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

.contact-right-section textarea {
  resize: none;
}

.send-button {
  background-color: #d9d9d9;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 600; /* Semi Bold */
  font-size: 20px;
}

@media (max-width: 768px) {
  .contact-sections-container {
    flex-direction: column;
  }

  .contact-left-section,
  .contact-right-section {
    flex: 1 1 100%;
  }

  .contact-text-container-overlay {
    padding: 10px;
  }

  .contact-right-section {
    flex: 1 1 45%;
    padding: 50px;
    box-sizing: border-box;
  }

  .contact-text-container-overlay h1 {
    font-size: 48px; /* Reduce font size for mobile */
  }

  .contact-text-container-overlay p {
    font-size: 16px; /* Reduce font size for mobile */
  }
}

.alert {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #d4d4d4;
  background-color: #f8f8f8;
  color: #333;
  border-radius: 5px;
}
