/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Montserrat:wght@400;700&display=swap");

.home-card-container {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100vh; /* Full viewport height */
  max-height: 1000px; /* Ensure it doesn't exceed a certain height */
  overflow: hidden; /* Hide any overflow */
}

.crane-grab-scrap {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without stretching */
  margin: 0;
  padding: 0;
}

.home-overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 60px;
  box-sizing: border-box;
  color: white;
}

.home-text-container {
  max-width: 50%;
  text-align: left;
}

.home-text-container h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 72px;
  white-space: nowrap; /* Prevent the text from breaking */
}

.home-text-container p {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 300;
}

.home-button {
  padding: 10px 20px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  background-color: #f3b416;
  color: black;
  border: none;
  border-radius: 40px;
  margin-top: 10px;
}

.home-button:hover {
  color: white;
}

@media (max-width: 768px) {
  .home-text-container {
    max-width: 100%;
    text-align: center;
  }

  .home-overlay-content {
    padding: 10px;
  }

  .home-text-container h1 {
    font-size: 40px;
    white-space: normal; /* Allow the text to break on smaller screens */
  }

  .home-text-container p {
    font-size: 18px;
  }

  .home-button {
    padding: 8px 16px;
    font-size: 16px;
  }

  .home-card-container {
    height: 400px;
  }

  .crane-grab-scrap {
    height: 400px;
    object-fit: cover; /* Ensure the image covers the container without stretching */
  }
}

@media (max-width: 576px) {
  .home-text-container h1 {
    font-size: 30px; /* Further reduce font size for smaller screens */
  }

  .home-text-container p {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .home-button {
    padding: 8px 16px;
    font-size: 14px; /* Adjust button font size for smaller screens */
    margin-top: 20px; /* Increase margin to ensure spacing between sections */
  }
}

@media (min-width: 1200px) {
  .home-overlay-content {
    padding: 50px;
  }

  .home-text-container h1 {
    font-size: 60px;
    padding: 0px;
    max-width: 100%;
  }

  .home-text-container p {
    font-size: 20px;
  }

  .home-button {
    padding: 10px 20px;
    font-size: 18px;
  }

  .home-text-container {
    max-width: 40%;
  }
}
