/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600&family=Koulen&display=swap");

.about-card-container-overlay {
  position: relative;
}

.about-container {
  padding: 20px;
}

.about-image-container img {
  width: 100%;
  height: auto;
}

.about-workers-image {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.about-overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  color: white;
  text-align: center;
}

.about-text-container-overlay {
  padding: 20px;
  border-radius: 10px;
}

.about-text-container-overlay h1 {
  font-family: "Koulen", sans-serif;
  font-size: 96px;
  margin: 0 0 10px 0;
}

.about-text-container-overlay p {
  font-family: "Inter", sans-serif;
  font-weight: 200; /* Extra Light */
  font-size: 20px;
  margin: 0;
}

.about-full-width-container {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3b416;
}

.about-orange-background {
  background-color: #f3b416;
}

.about-image-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.about-services-image {
  max-width: 500px;
  height: auto;
  object-fit: cover;
}

.about-text-container {
  padding: 40px;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.commitment-container {
  padding: 40px;
  background-color: #f9f9f9;
}

.commitment-container p {
  padding: 40px 60px;
  font-family: "Inter", sans-serif;
  font-weight: 400; /* Regular */
  font-size: 24px;
  margin-top: 1rem;
  color: black;
  text-align: center;
}

.commitment-container h2 {
  font-family: "Koulen", sans-serif;
  font-size: 55px;
  font-weight: 400;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
}

.horizontal-bar {
  display: inline-block;
  width: 3ch; /* Adjust the width to match the length of three dashes */
  height: 2px; /* Adjust the height as needed */
  background-color: currentColor; /* Match the color of the text */
  vertical-align: middle;
  margin-left: 0.5em; /* Adjust the spacing as needed */
}

.about-text-container h4 {
  font-family: "Inter", sans-serif;
  font-weight: 600; /* Semi Bold */
  font-size: 20px;
  display: flex;
  align-items: center;
}

.about-text-container h1 {
  font-family: "Koulen", sans-serif;
  font-size: 48px;
  margin-top: 1rem;
}

.about-text-container p {
  font-family: "Inter", sans-serif;
  font-weight: 400; /* Regular */
  font-size: 20px;
  margin-top: 1rem;
  color: black;
}

@media (max-width: 768px) {
  .mission-vision-image-container {
    display: block; /* Stack image and overlay vertically */
    position: relative;
  }

  .mission-vision-overlay {
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    transform: none; /* Reset transformation */
    padding: 20px;
    box-sizing: border-box;
    border-radius: 0;
    background-color: rgba(
      217,
      217,
      217,
      0.8
    ); /* Adjusted background color with transparency */
    color: black; /* Ensure contrast with the new background */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  }

  .mission-vision-image {
    width: 100%;
    height: auto;
  }

  .mission-text-center {
    font-size: 36px; /* Reduce font size for mobile */
  }

  .mission-vision-overlay h3 {
    font-size: 24px; /* Reduce font size for mobile */
  }

  .commitment-container p {
    font-size: 16px; /* Reduce font size for mobile */
    text-align: left;
    padding: 0;
  }

  .about-full-width-container {
    padding: 10px;
    height: auto; /* Adjust height for mobile */
  }

  .about-image-container {
    padding: 10px;
  }

  .about-text-container {
    padding: 20px;
  }

  .about-text-container-overlay {
    padding: 10px;
  }

  .about-text-container-overlay h1 {
    font-size: 48px; /* Reduce font size for mobile */
  }

  .about-text-container-overlay p {
    font-family: "Inter", sans-serif;
    font-weight: 200; /* Extra Light */
    font-size: 16px;
    margin: 0;
  }

  /* Hide Our Vision on mobile */
  /* .mission-vision-overlay h3:nth-of-type(2),
  .mission-vision-overlay p:nth-of-type(2) {
    display: none;
  } */
}

.h2,
.h3 {
  font-weight: bold;
}

.meet-the-team-container {
  background-color: #e0e0e0;
  padding: 60px 20px;
  text-align: center;
}

.meet-the-team-container h2 {
  font-family: "Koulen", sans-serif;
  font-size: 55px;
  font-weight: 400;
  margin-bottom: 40px;
}

.team-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.team-card {
  background-color: #f3b416;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 500px;
  max-width: 300px;
  text-align: center;
}

.team-member-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.team-card h3 {
  font-family: "Koulen", sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
}

.team-card p {
  font-family: "Inter", sans-serif;
  font-weight: 400; /* Regular */
  font-size: 1rem;
  margin-top: 10px;
}

@media (min-width: 992px) {
  /* Applies to large screens */
  .about-text-container {
    padding-right: 160px; /* Added to ensure equal padding on both sides */
  }
}
