/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Koulen&display=swap");

.whychoose-container {
  margin-top: 0;
}

.whychoose-card-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.whychoose-text-container {
  flex: 1;
  padding: 50px;
  background-color: #2a2b2d;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whychoose-text-container h1 {
  font-family: "Koulen", sans-serif;
  font-size: 48px;
  font-weight: 400;
  color: #f3b416;
}

.whychoose-text-container h3,
p {
  color: white;
}

.whychoose-list {
  list-style: none; /* Remove default bullet points */
  padding-left: 0; /* Remove default padding */
  margin-top: 20px; /* Adjust spacing between header and list */
}

.whychoose-list li {
  position: relative;
  padding-left: 50px; /* Increased space for the custom bullet */
  margin-bottom: 20px; /* Adjust as needed */
}

.whychoose-list li h3 {
  font-family: "Inter", sans-serif;
  font-weight: 500; /* Medium */
  font-size: 24px;
  margin-bottom: 10px; /* Space between title and text */
}

.whychoose-list li p {
  font-family: "Inter", sans-serif;
  font-weight: 300; /* Light */
  font-size: 16px;
  margin: 0; /* Remove default margin */
}

.whychoose-list li::before {
  content: "\2713"; /* Unicode character for check mark */
  position: absolute;
  left: 0;
  top: 30%; /* Align vertically center */
  transform: translateY(-50%); /* Center the icon vertically */
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px; /* Adjust size as needed */
}

.whychoose-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whychoose-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .whychoose-card-container {
    flex-direction: column;
  }

  .whychoose-text-container {
    padding: 20px;
  }

  .whychoose-image {
    height: auto;
  }
}
