/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400&family=Koulen&display=swap");

.services-container {
  margin-top: 0;
}

.card-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

.card-container:first-child {
  background-color: orange;
}

.services-text-container {
  flex: 1;
  padding: 50px; /* Increase padding here */
  /* background-color: #f0f0f0; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
}

.services-text-container-one {
  flex: 1;
  padding: 50px; /* Increase padding here */
  background-color: orange;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-container-overlay {
  position: relative;
  margin: 0;
  padding: 0;
}

.workers-image {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
}

.overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  color: white;
}

.text-container-overlay {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.text-container-overlay h1 {
  font-family: "Koulen", sans-serif;
  font-size: 96px;
  margin: 0 0 10px 0;
}

.text-container-overlay p {
  font-family: "Inter", sans-serif;
  font-weight: 200; /* Extra Light */
  font-size: 20px;
  margin: 0;
}

.services-text-container h1,
.services-text-container-one h1 {
  font-family: "Koulen", sans-serif;
  font-size: 48px;
  margin: 0 0 10px 0;
}

.services-text-container p,
.services-text-container-one p {
  font-family: "Inter", sans-serif;
  font-weight: 300; /* Light */
  font-size: 20px;
  margin: 0;
  color: black;
}

/* .services-text-container p {
  color: black;
  font-family: "Inter", sans-serif;
  font-size: 20px;
} */

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
    /* padding-top: 20px; */
  }

  .card-container:nth-child(odd) {
    flex-direction: column-reverse;
    /* padding-top: 10px; */
  }

  .card-container:nth-child(even) {
    /* flex-direction: column-reverse; */
    padding-top: 10px;
  }

  .text-container-overlay {
    padding: 10px;
  }

  .services-image {
    height: auto;
  }

  .text-container-overlay h1 {
    font-size: 48px; /* Reduce font size for mobile */
  }

  .text-container-overlay p {
    font-size: 16px; /* Reduce font size for mobile */
  }

  .services-text-container h1,
  .services-text-container-one h1 {
    font-size: 32px; /* Reduce font size for mobile */
  }

  .services-text-container p,
  .services-text-container-one p {
    font-size: 16px; /* Reduce font size for mobile */
  }
}
