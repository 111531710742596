/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Koulen&display=swap");

.main-footer {
  background-color: #2a2b2d;
  color: #333;
}

.above-footer,
.below-footer {
  padding: 20px 0;
}

.above-footer .orange-bar {
  background-color: orange;
  height: 5px;
  margin-bottom: 10px;
}

.above-footer {
  color: white;
}

.menu-column ul,
.find-us-column ul {
  list-style: none;
  padding: 0;
}

.menu-column ul li,
.find-us-column ul li {
  margin-bottom: 10px;
  position: relative; /* Ensure positioning for the icons */
  padding-left: 30px; /* Space for the icons */
}

.menu-column h2,
.find-us-column h2,
.share-column h2 {
  font-family: "Koulen", sans-serif;
  font-size: 40px;
}

.menu-column ul li a,
.find-us-column ul li,
.working-hours {
  font-family: "Inter", sans-serif;
  font-weight: 300; /* Light */
  font-size: 16px;
}

.working-hours i {
  color: orange;
}

.find-us-column i {
  position: absolute; /* Position the icons */
  left: 0; /* Align them to the left */
  top: 0; /* Align them to the top */
  color: orange; /* Set the icon color to orange */
}

.find-us-column .address {
  display: block; /* Ensure the addresses are block elements */
  margin-top: 20px; /* Add margin for spacing between addresses */
}

.social-icons a {
  margin-right: 10px;
}

.below-footer {
  background-color: black;
}

.below-footer .copyright {
  text-align: center;
  margin: 0;
  color: #d9d9d9;
  font-family: "Inter", sans-serif;
  font-weight: lighter; /* Light */
  font-size: 18px;
}

@media (max-width: 768px) {
  .main-footer .row {
    flex-direction: column;
    align-items: center;
  }

  .menu-column ul,
  .find-us-column ul,
  .share-column .social-icons {
    text-align: center;
  }

  .logo-column,
  .menu-column,
  .find-us-column,
  .share-column {
    text-align: center;
  }

  .find-us-column ul li {
    padding-left: 40px; /* Ensure enough space for the icons */
  }

  .find-us-column i {
    left: 10%; /* Adjust icon alignment */
    transform: translateX(-50%); /* Adjust horizontal alignment */
  }
}
