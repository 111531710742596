/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&family=Koulen&display=swap");

.get-quote-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  background-color: #333;
  border-radius: 15px;
  max-width: 1200px;
  margin: 0 auto;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-section-quote {
  flex: 1;
  padding: 30px;
  background-color: #333;
  color: white;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.left-section-quote h2 {
  font-family: "Koulen", sans-serif;
  font-size: 40px;
}

.right-section-quote {
  flex: 1;
  padding: 20px;
  background-color: #f3b416;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.button-container {
  display: flex;
  gap: 20px;
}

.quote-button,
.phone-button {
  background-color: white;
  color: #333;
  border: none;
  padding: 15px 30px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-weight: 600; /* Semi Bold */
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; /* Remove underline from link */
}

.phone-button svg {
  margin-right: 8px;
}

.quote-button:hover,
.phone-button:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .get-quote-container {
    flex-direction: column;
    padding: 20px;
    margin: 5%;
    color: white;
  }

  .left-section-quote,
  .right-section-quote {
    border-radius: 15px;
    text-align: center;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .quote-button,
  .phone-button {
    width: 100%;
  }
}
