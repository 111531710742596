.projects-container {
  padding: 50px;
}

.projects-card {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin-bottom: 20px; /* Ensures cards have spacing between them */
  height: 350px; /* Set a fixed height for the cards */
  display: flex;
  flex-direction: column;
}

.projects-card-body {
  padding: 0; /* Remove padding from card body */
  flex: 1; /* Allow the card body to fill the available space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .projects-card {
    margin-bottom: 20px;
    height: 300px; /* Adjust height for smaller screens if necessary */
  }
}

.projects-text-center {
  font-family: "Koulen", sans-serif;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
}
